import React, { useState, useEffect } from 'react';
import axios from 'axios';


const Rows = (props) => {
    return(
        <tr>
            <td>{props.symbol}</td>
            <td>{props.strategy}</td>
            <td>{props.type}</td>
            <td>{props.price}</td>
            <td>{props.sellStrike}</td>
            <td>{props.buyStrike}</td>
            <td>{props.expiry}</td>
            <td>{props.premium}</td>
            <td>{props.width}</td>
            <td>{props.premWidth}</td>
            <td>{props.ivRank}</td>
            <td>{props.earningsDate}</td>
        </tr>
    )
}

const CreditSpread = () => {

    const [data, setData] = useState([]);

    const getData = async () => {
        try {
            const res = await axios.get("https://bouzy-react.herokuapp.com/api-v1/creditspread");
            setData(prevData  => prevData = res.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getData();
    }, [])

    const rows = data.map((item, index) => {
        return (
            <Rows 
                key={index}
                symbol={item.Symbol}
                strategy={item.Strategy}
                type={item.Type}
                price={item.Price}
                sellStrike={item['Sell Strike']}
                buyStrike={item['Buy Strike']}
                expiry={item.Expiry}
                premium={item.Premium}
                width={item.Width}
                premWidth={item['Prem/Width']}
                ivRank={item['IV Rank']}
                earningsDate={item['Earnings Date']}
            />
        )
    }) 

    return(
        <div>
            <table id="stock-tables-credit-sprd">
                <tbody>
                    <tr>
                        <th>Symbol</th>
                        <th>Strategy</th>
                        <th>Type</th>
                        <th>Price</th>
                        <th>Sell Strike</th>
                        <th>Buy Strike</th>
                        <th>Expiry</th>
                        <th>Premium</th>
                        <th>Width</th>
                        <th>Prem/Width</th>
                        <th>IV/Rank</th>
                        <th>Earnings Date</th>
                    </tr>
                    {rows}
                </tbody>
            </table>
        </div>)}

export default CreditSpread;