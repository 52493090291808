import React, { useState, useEffect } from 'react';
import axios from 'axios';


const Rows = (props) => {
    return(
        <tr>
            <td>{props.symbol}</td>
            <td>{props.action}</td>
            <td>{props.expiry}</td>
            <td>{props.daysToExpiry}</td>
            <td>{props.strikePrice}</td>
            <td>{props.midPrice}</td>
            <td>{props.bidPrice}</td>
            <td>{props.askPrice}</td>
            <td>{props.impliedVol}</td>
            <td>{props.earningsDate}</td>
            <td>{props.earningsFlag}</td>
            <td>{props.stockPrice}</td>
            <td>{props.rawReturn}</td>
            <td>{props.annualizedReturn}</td>
            <td>{props.distToStrike}</td>
        </tr>
    )
}

const ShortPuts = () => {

    const [data, setData] = useState([]);

    const getData = async () => {
        try {
            const res = await axios.get("https://bouzy-react.herokuapp.com/api-v1/shortputs");
            setData(prevData  => prevData = res.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getData();
    }, [])

    const rows = data.map((item, index) => {
        return (
            <Rows 
                key={index}
                symbol={item.Symbol}
                action={item.Action}
                expiry={item.Expiry}
                daysToExpiry={item['Days To Expiry']}
                strikePrice={item['Strike Price']}
                midPrice={item['Mid Price']}
                bidPrice={item['Bid Price']}
                askPrice={item['Ask Price']}
                impliedVol={item['Implied Volatility Rank']}
                earningsDate={item['Earnings Date']}
                earningsFlag={item['Earnings Flag']}
                stockPrice={item['Stock Price']}
                rawReturn={item['Raw Return']}
                annualizedReturn={item['Annualized Return']}
                distToStrike={item['Distance To Strike']}
            />
        )
    }) 

    return(
        <div>
            <table id="stock-tables-short-puts">
                <tbody>
                    <tr>
                        <th>Symbol</th>
                        <th>Action</th>
                        <th>Expiry</th>
                        <th>Days to Expire</th>
                        <th>Strike Price</th>
                        <th>Mid Price</th>
                        <th>Bid Price</th>
                        <th>Ask Price</th>
                        <th>Implied Volatility Rank</th>
                        <th>Earnings Date</th>
                        <th>Earnings Flag</th>
                        <th>Stock Price</th>
                        <th>Raw Return</th>
                        <th>Annualized Return</th>
                        <th>Distance to Strike</th>
                    </tr>
                    {rows}
                </tbody>
            </table>
        </div>)}

export default ShortPuts;