import React, {useEffect, useState} from 'react';
import CreditSpread from './comps/CreditSpread.js';
import CoveredCalls from './comps/CoveredCalls.js';
import ShortPuts from './comps/ShortPuts.js';
import axios from 'axios';


const credBtn = document.getElementById('credit-spread-btn');
const shortBtn = document.getElementById('short-puts-btn');
const coveredBtn = document.getElementById('covered-calls-btn');
const gapsBtn = document.getElementById('gaps-btn');
const volumeBtn = document.getElementById('volume-btn');
const straddleBtn = document.getElementById('straddle-btn');
const time = document.getElementById('time');
const contactLink = document.getElementById('contact-link');
const contactBox = document.getElementById('contact-box');

const longCallBtn = document.getElementById('long-call-btn');
const shortCallBtn = document.getElementById('short-call-btn');
const longPutBtn = document.getElementById('long-put-btn');
const shortPutBtn = document.getElementById('short-put-btn');

const closeBtnLc = document.getElementById('desc-close-lc');
const closeBtnLp = document.getElementById('desc-close-lp');
const closeBtnSc = document.getElementById('desc-close-sc');
const closeBtnSp = document.getElementById('desc-close-sp');

const longCallDesc = document.getElementById('strat-desc-long-call');
const shortCallDesc = document.getElementById('strat-desc-short-call');
const longPutDesc = document.getElementById('strat-desc-long-put');
const shortPutDesc = document.getElementById('strat-desc-short-put');


const allOtherBtns = [credBtn, shortBtn, coveredBtn, gapsBtn, volumeBtn, straddleBtn];

const switchAllOtherBtns = (currentBtn) => {
    for (let btn of allOtherBtns) {
        if (btn != currentBtn){
            btn.style.backgroundColor = 'white';
            btn.style.color = '#38a6f5';
        }
    }
}

const App = () => {

    const [table, setTable] = useState('credit');
    const [pass, setPass] = useState();
    const [email, setEmail] = useState();
    const [msg, setMsg] = useState(null);
    const [auth, setAuth] = useState(null);
    const [haveAcc, setHaveAcc] = useState('yes');
    
    
    const changeCred = () => {
        if(auth != null){
            setTable(prevTable => prevTable = 'credit');
            credBtn.style.backgroundColor = '#2193e8';
            credBtn.style.color = 'white';
            switchAllOtherBtns(credBtn);
        }
    }
    const changeShort = () => {
        if(auth != null){
            setTable(prevTable => prevTable = 'short');
            shortBtn.style.backgroundColor = '#2193e4';
            shortBtn.style.color = 'white';
            switchAllOtherBtns(shortBtn);
        }
    }
    const changeCovered = () => {
        if(auth != null){
            setTable(prevTable => prevTable = 'covered');
            coveredBtn.style.backgroundColor = '#2193e4';
            coveredBtn.style.color = 'white';
            switchAllOtherBtns(coveredBtn);
        }
    }
    const changeGaps = () => {
        if(auth != null){
            setTable(prevTable => prevTable = 'gaps');
            gapsBtn.style.backgroundColor = '#2193e4';
            gapsBtn.style.color = 'white';
            switchAllOtherBtns(gapsBtn);
        }
    }
    const changeVolume = () => {
        if(auth != null){
            setTable(prevTable => prevTable = 'volume');
            volumeBtn.style.backgroundColor = '#2193e4';
            volumeBtn.style.color = 'white';
            switchAllOtherBtns(volumeBtn);
        }
    }
    const changeStraddle = () => {
        if(auth != null){
            setTable(prevTable => prevTable = 'straddle');
            straddleBtn.style.backgroundColor = '#2193e4';
            straddleBtn.style.color = 'white';
            switchAllOtherBtns(straddleBtn);
        }
    }

    const contactVisibilityOn = () => {
        contactBox.style.display = 'block'
    }
    const contactVisibilityOff = () => {
        contactBox.style.display = 'none'
    }

    credBtn.addEventListener('click', changeCred);
    shortBtn.addEventListener('click', changeShort);
    coveredBtn.addEventListener('click', changeCovered);
    gapsBtn.addEventListener('click', changeGaps);
    volumeBtn.addEventListener('click', changeVolume);
    straddleBtn.addEventListener('click', changeStraddle);
    contactLink.addEventListener('mouseover', contactVisibilityOn);
    contactLink.addEventListener('mouseout', contactVisibilityOff);

    
    const allDesc = [longCallDesc, shortCallDesc, longPutDesc, shortPutDesc];
    
    const closeAllDesc = () => {
        for (let desc of allDesc){
            desc.style.display = 'none';
        }
    }
    
    const closeAllOtherDesc = (currentDesc) => {
        for (let desc of allDesc){
            if (currentDesc == desc){
                continue;
            }
            desc.style.display = 'none';
        }
    }
    
    
    longCallBtn.addEventListener('click', () => {
        if(auth != null){
            longCallDesc.style.display = 'block';
            closeAllOtherDesc(longCallDesc);
        }
    })
    shortCallBtn.addEventListener('click', () => {
        if(auth != null){
            shortCallDesc.style.display = 'block';
            closeAllOtherDesc(shortCallDesc);
        }
    })
    longPutBtn.addEventListener('click', () => {
        if(auth != null){
            longPutDesc.style.display = 'block';
            closeAllOtherDesc(longPutDesc);
        }
    })
    shortPutBtn.addEventListener('click', () => {
        if(auth != null){
            shortPutDesc.style.display = 'block';
            closeAllOtherDesc(shortPutDesc);
        }
    })
    closeBtnLc.addEventListener('click', closeAllDesc)
    closeBtnLp.addEventListener('click', closeAllDesc)
    closeBtnSc.addEventListener('click', closeAllDesc)
    closeBtnSp.addEventListener('click', closeAllDesc)


    const userInfo = {
        'email': email,
        'pass': pass
    }

    const register = async () => {
        if ((email == null) || (pass == null)){
            return setMsg(prevMsg => prevMsg = "must enter valid values");
        }
        try {
            const res = await axios.post('https://bouzy-auth.herokuapp.com/signup', userInfo, {
                withCredentials:true
            });
            if (res.data.msg){
                setAuth(prevAuth => prevAuth = res.data.msg);
            } else if (res.data.message){
                setMsg(prevMsg => prevMsg = res.data.message);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const verify = async () => {
        if ((email == null) || (pass == null)){
            return setMsg(prevMsg => prevMsg = "must enter valid values");
        }
        try {
            const res = await axios.post('https://bouzy-auth.herokuapp.com/login', userInfo, {
                withCredentials:true
            });
            if (res.data.msg){
                setAuth(prevAuth => prevAuth = res.data.msg);
            } else if (res.data.message){
                setMsg(prevMsg => prevMsg = res.data.message);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const forgotPassword = async () => {
        try {
            const res = await axios.post('http://localhost:3001/forgot-password', {'email': userInfo.email}, {
                withCredentials:true
            });
        } catch (error) {
            console.log(error);
        }

    }

    const getTime = async () => {
        try {
            const dateTime = await axios.get("https://bouzy-react.herokuapp.com/api-v1/time");
            time.innerHTML = dateTime.data;
            
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getTime();
    }, [auth]);
    
    if(auth == 'successful'){//auth != ''
        if (table == 'credit'){
            return (
                <div id="scroll-credit">
                    <CreditSpread />
                </div>
            )
        }
        else if (table == 'short'){
            return (
                <div id="scroll-short">
                    <ShortPuts />
                </div>
            )
        }
        else if (table == 'covered'){
            return (
                <div id="scroll-covered">
                    <CoveredCalls />
                </div>
            )
        }
        else if (table == 'gaps'){
            return <iframe id="gaps" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTS-SiwjrlK2m2n5QkueSGmXLAfGEmLe2zXf1IeUMOwRlK8cx-BCk4ENKXj4hE5sw8uD5GAvUiGXs1Z/pubhtml?gid=1121795502&amp;widget=true&amp;headers=false"></iframe>
        }
        else if (table == 'straddle'){
            return <iframe id="straddle-income" src="https://syrahtrading-my.sharepoint.com/:x:/p/tzhang/EbeTuF1xHQhLn0Ld7fY2wUcB03w0pSpM-KgelpS3xKpCQg?e=SLdJUo&action=embedview&wdbipreview=true&wdDownloadButton=true"></iframe>
        }
        else if (table == 'volume'){
            return <iframe id="unusual-volume" src="https://syrahtrading-my.sharepoint.com/:x:/p/tzhang/EZbQcY2A5BJEofJ_5aQRXZwBvbK3fy8o6pqJwzawKHJo7A?e=rUoRmf&action=embedview&wdbipreview=true"></iframe>
        }

    } else {
        if (haveAcc == 'no'){
            return(
                <div className='signup'>
                    <h1>Sign Up</h1>
                    <div className='signup-fields'>
                        <input type='email' placeholder='email' onChange={(event) => setEmail(prevEmail => prevEmail = event.target.value)}/>
                        <input type='password' placeholder='password' onChange={(event) => setPass(prevPass => prevPass = event.target.value)}/>
                    </div>
                    <div className='signup-btns'>
                        <div className='signup-btns-opt'>
                            <span onClick={() => {
                                setHaveAcc(prevHaveAcc => prevHaveAcc = 'yes')}}
                                >already have an account?</span>
                            {/*<span onClick={() => {setHaveAcc(prevHaveAcc => prevHaveAcc = 'forgot')}}>forgot password</span>*/}
                        </div>
                        <button onClick={register}>Sign up</button>
                    </div>
                    <div>
                        <span className='msg'>{msg}</span>
                    </div>
                </div>
            )
        } else if (haveAcc == 'yes'){
            return(
                <div className='signup'>
                    <h1>Login</h1>
                    <div className='signup-fields'>
                        <input type='email' placeholder='email' onChange={(event) => setEmail(prevEmail => prevEmail = event.target.value)}/>
                        <input type='password' placeholder='password' onChange={(event) => setPass(prevPass => prevPass = event.target.value)}/>
                    </div>
                    <div className='signup-btns'>
                        <div className='signup-btns-opt'>
                            <span onClick={() => {
                                setHaveAcc(prevHaveAcc => prevHaveAcc = 'no')}}
                                >dont have an account?</span>
                            {/*<span onClick={() => {setHaveAcc(prevHaveAcc => prevHaveAcc = 'forgot')}}>forgot password</span>*/}
                        </div>
                        <button onClick={verify}>Log in</button>
                    </div>
                    <div>
                        <span className='msg'>{msg}</span>
                    </div>
                </div>
            )
        } else if (haveAcc == 'forgot'){
            return(
                <div className='signup'>
                    <h1>Forgot Password</h1>
                    <p id='forgot-text'>A temperary link to reset your password will be sent to your email address</p>
                    <div className='signup-fields'>
                        <input type='email' placeholder='email' onChange={(event) => setEmail(prevEmail => prevEmail = event.target.value)}/>
                    </div>
                    <div className='signup-btns'>
                        <div className='signup-btns-opt'>
                            <span onClick={() => {
                                setHaveAcc(prevHaveAcc => prevHaveAcc = 'yes')}}
                                >already have an account?</span>
                            <span onClick={() => {
                                setHaveAcc(prevHaveAcc => prevHaveAcc = 'no')}}
                                >dont have an account?</span>
                        </div>
                        <button onClick={forgotPassword}>Send Email</button>
                    </div>
                    <div>
                        <span className='msg'>{msg}</span>
                    </div>
                </div>
            )
        } 
    }
    
}

export default App;